* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --primary: #2b2b2b;
  --secondary: #757575;
  --title: white;
}
input[type='email']:not(.browser-default):focus:not([readonly]),
input[type='password']:not(.browser-default):focus:not([readonly]),
input[type='date']:not(.browser-default):focus:not([readonly]),
textarea:not(.browser-default):focus:not([readonly]) {
  border-color: dodgerblue;
  box-shadow: none;
}

.animated-arrow-container {
  position: relative;
  left: 90%;
  top: 20px;
  width: 20px;
  z-index: 11;
  color: var(--secondary);
}
.animated-arrow {
  animation: slide1 1.1s ease-in-out infinite;
}

@keyframes slide1 {
  0%,
  100% {
    transform: translate(0, 0) rotate(20deg);
  }

  50% {
    transform: translate(4px, -12px) rotate(20deg);
  }
}

.merge-container,
.merge-container-custom {
  display: flex;
  justify-content: space-around;
  background-color: white;
  position: fixed;
  top: 56px;
  padding: 3px 0;
  width: 100%;
  z-index: 12;
}

.merge-container-custom {
  top: 66px;
}

.meal-card-check {
  position: absolute;
  left: 88%;
}

.check-unclicked,
.check-clicked,
.check-hovered {
  position: relative;
  top: 20px;
  width: 20px;
  height: 20px;
  background-color: #eee;
  transition: 0.2s;
  cursor: pointer;
  z-index: 0;
}

.check-clicked {
  background-color: dodgerblue;
}

@media only screen and (min-width: 321px) {
  .meal-card-check {
    left: 89%;
  }
}
@media only screen and (min-width: 348px) {
  .meal-card-check {
    left: 90%;
  }
}
@media only screen and (min-width: 376px) {
  .meal-card-check {
    left: 91%;
  }
}
@media only screen and (min-width: 426px) {
  .meal-card-check {
    left: 92%;
  }
}

@media only screen and (min-width: 601px) {
  nav,
  nav .nav-wrapper i,
  nav a.sidenav-trigger,
  nav a.sidenav-trigger i {
    height: 56px;
    line-height: 56px;
  }

  nav .nav-wrapper i,
  nav a.sidenav-trigger,
  nav a.sidenav-trigger i {
    font-size: 30px;
    cursor: pointer;
  }

  nav .brand-logo {
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
  }

  .animated-arrow-container {
    left: 94%;
  }

  /* .merge-container,
  .merge-container-custom {
    width: 82%;
    left: 9%;
  } */
}

@media only screen and (min-width: 993px) {
  .animated-arrow-container {
    left: 96%;
  }

  .merge-container,
  .merge-container-custom {
    width: 60%;
    left: 20%;
  }
}

@media only screen and (min-width: 1025px) {
  .check-hovered {
    background-color: #ccc;
  }
}

nav {
  font-family: 'Iceland';
  background-color: black;
  color: var(--title);
}

.navbar-fixed {
  height: 56px;
}

nav .sidenav-trigger {
  margin: 0;
  color: var(--title);
}

nav .brand-logo {
  font-size: 2.7rem;
}

.sidenav li > a {
  height: 4.5rem;
  line-height: 4.5rem;
  font-size: 1.2rem;
  color: var(--secondary);
  font-weight: bold;
}

.sidenav li > a > i.material-icons {
  height: 4.5rem;
  line-height: 4.5rem;
}

input {
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
  border-radius: 0;
  -webkit-border-radius: 0px;
}

input#search {
  border: 1px white solid;
  background-color: rgb(94, 93, 93);
  color: white;
  margin: 8px;
  padding: 10px 15px;
  width: 93%;
  outline: none;
}

input#search:focus,
input#search:active,
#search:hover {
  border: 1px solid dodgerblue;
  color: white;
}

#search-x {
  position: absolute;
  top: 0px;
  right: 25px;
  color: white;
  font-size: 18px;
  cursor: pointer;
}

#search-x:hover {
  color: dodgerblue;
}

ul.query-results {
  border-top: 1px var(--secondary) solid;
  width: 100%;
  margin: 0 auto;
  position: relative;
  top: 55px;
  display: table;
  table-layout: fixed;
}

li.query-results {
  color: var(--secondary);
  padding: 7px 13px;
  font-size: 1rem;
  line-height: 1.2rem;
  border-bottom: 1px solid #efefed;
  background-color: white;
  overflow: hidden;
  white-space: nowrap;
  outline: none;
}

li.query-results:hover,
li.query-results:focus {
  background-color: rgba(240, 240, 240, 0.5);
  cursor: pointer;
  color: dodgerblue;
}

.callout-container {
  position: relative;
  top: 125px;
  width: 91%;
  margin: 0 auto;
  text-align: center;
  color: var(--secondary);
}

.callout {
  border-top: 1px dodgerblue solid;
  border-bottom: 1px dodgerblue solid;
  padding: 6px 5px;
}

.search-subheader {
  position: absolute;
  top: 105px;
  left: 2%;
  width: 96%;
  text-align: center;
  font-weight: bold;
  font-size: 0.95rem;
  color: dodgerblue;
}

/* REMOVE */
.query-group {
  font-family: 'Iceland';
  color: var(--primary);
  font-size: 1.3rem;
  line-height: 1.2rem;
  padding: 5px;
  text-align: center;
}

/* NUTRITION LABEL */
.nutrition-display {
  position: fixed;
  top: 80px;
  z-index: 100;
}

.nutrition-display-side-nav {
  left: 10px;
}

.custom-label-display {
  position: relative;
  top: 0;
  left: 0;
  right: 0;
}

.nutrition-facts {
  margin: 5px auto;
  padding: 4px;
  width: 280px;
  border: 1px solid black;
  font-size: 0.95rem;
  line-height: 1rem;
  background-color: white;
}

#simple-facts {
  border-color: dodgerblue;
}

.estimated-tag {
  border: 1px solid dodgerblue;
  padding: 2px;
  margin: 10px auto 0 auto;
  width: 11rem;
  text-align: center;
  color: dodgerblue;
  font-weight: bold;
}

.nutrition-facts table {
  width: 102%;
}

#custom-dv-label {
  margin-top: 10px;
  width: 309px;
  padding: 4px;
}

#custom-create-label {
  margin-top: 10px;
  width: 290px;
  padding: 4px;
}

.sublabel {
  padding-left: 10px;
}

.thick-row {
  border-bottom: 7px solid black;
}

.nutrition-facts-title {
  font-weight: bold;
  font-size: 1.7rem;
  line-height: 1.7rem;
}

th,
td {
  text-align: right;
  padding: 2px;
  border-bottom: 1px solid black;
  white-space: wrap;
}
th {
  font-weight: bold;
}

th:first-child,
td:first-child {
  text-align: left;
  width: 120px;
}

.no-underline {
  border: none;
}

.mini-header {
  font-size: 0.9rem;
}

.calories {
  font-size: 1.4rem;
}

/* CUSTOM LABEL */
.gram-input {
  text-align: right;
  font-size: 0.95rem;
  width: 60px;
}

select {
  color: var(--secondary);
  height: 2rem;
  width: 5rem;
  float: right;
}

/* LABEL CLOSE 'X' ~~ not currently used ~~*/
#label-close-icon {
  color: var(--secondary);
  background-color: white;
  font-size: 1.4rem;
  position: absolute;
  top: 5px;
  right: 5px;
  border: 1px solid var(--secondary);
  border-radius: 15px;
  cursor: pointer;
  transition: 0.3s;
}
#label-close-icon:hover {
  color: #969696;
  border: 1px solid #969696;
}

/* LABEL 'CLOSE' */
#label-close {
  position: absolute;
  right: 0px;
  top: -17px;
  padding: 2px 5px;
  color: dodgerblue;
  background-color: white;
  font-size: 1rem;
  border: 1px solid dodgerblue;
  border-bottom: none;
  /* border-radius: 3px; */
  cursor: pointer;
  transition: 0.3s;
}
#label-close:hover {
  color: white;
  background-color: dodgerblue;
}

/* CUSTOM ADD FORM */
/* white box behind form to cover overflowing search results in some cases */
#custom-add-form {
  position: relative;
  top: -5px;
  margin: 0 auto;
  height: 200px;
  background-color: white;
}

.edit-servings-container {
  display: flex;
  justify-content: space-around;
  position: relative;
  margin: 10px 35px;
}

input.edit-gram-input {
  margin: 5px 0;
  width: 5rem;
  height: 2.2rem;
  line-height: 2.2rem;
  font-size: 1rem;
  text-align: center;
  color: dodgerblue;
  background-color: white;
  border: 1px solid dodgerblue;
  border-radius: 5px;
}

select.edit-unit {
  margin: 5px 0;
  padding: 5px;
  width: 7rem;
  height: 2.2rem;
  line-height: 2rem;
  font-size: 1rem;
  text-align: center;
  color: dodgerblue;
  background-color: white;
  border: 1px solid dodgerblue;
  border-radius: 5px;
  cursor: pointer;
}

input#custom-gram-input {
  position: absolute;
  top: 13px;
  width: 5rem;
  height: 2.2rem;
  line-height: 2.2rem;
  font-size: 1rem;
  text-align: center;
  color: dodgerblue;
  background-color: white;
  border: 1px solid dodgerblue;
  border-radius: 5px;
}

input.outline-input {
  width: 4.1rem;
  height: 2rem;
  line-height: 2rem;
  font-size: 0.9rem;
  text-align: center;
  color: dodgerblue;
  background-color: white;
  border: 1px solid dodgerblue;
  border-radius: 5px;
  margin-right: 3px;
}

select.outline-select {
  left: 5.5rem;
  top: 13px;
  width: 5.3rem;
  height: 2rem;
  line-height: 2rem;
  font-size: 1rem;
  text-align: center;
  padding: 5px 0;
  color: dodgerblue;
  background-color: white;
  border: 1px solid dodgerblue;
  border-radius: 5px;
  cursor: pointer;
}

select#unit-select {
  position: absolute;
  left: 5.5rem;
  top: 13px;
  width: 7rem;
  height: 2.2rem;
  line-height: 2rem;
  font-size: 1rem;
  text-align: center;
  padding: 5px;
  color: dodgerblue;
  background-color: white;
  border: 1px solid dodgerblue;
  border-radius: 5px;
  cursor: pointer;
}

.button-blue {
  position: relative;
  width: 100%;
  max-width: 315px;
  background-color: dodgerblue;
  color: white;
  border: 1px solid dodgerblue;
  border-radius: 5px;
  font-weight: bold;
  padding: 10px 12px;
  transition: 0.3s;
  cursor: pointer;
}

.button-blue:hover,
.button-blue:focus {
  background-color: white;
  color: dodgerblue;
}

.button-white,
.button-white:focus {
  position: relative;
  width: 100%;
  max-width: 315px;
  background-color: white;
  color: var(--secondary);
  border: 1px solid var(--secondary);
  border-radius: 5px;
  font-weight: bold;
  padding: 10px 12px;
  transition: 0.3s;
  cursor: pointer;
}

.button-white:hover,
.button-white:focus {
  background-color: var(--secondary);
  color: white;
}

#log-out-button,
#change-password-button,
#sign-up-button,
#custom-label-save-button {
  color: dodgerblue;
  border: 1px solid dodgerblue;
}

#log-out-button:hover,
#change-password-button:hover,
#sign-up-button:hover,
#custom-label-save-button:hover,
#log-out-button:focus,
#change-password-button:focus,
#sign-up-button:focus {
  background-color: dodgerblue;
  color: white;
}

#clear-button {
  font-weight: normal;
  width: 30%;
  height: 2.5rem;
  padding: 0;
  margin: 5px 0;
}
#clear-button:hover,
#clear-button:focus {
  background-color: #f1f1f1;
  color: var(--primary);
}

.delete-button,
.modal-delete {
  color: red;
  border: 1px solid red;
}

.delete-button {
  margin: 20px 0;
}

.delete-button:hover,
.delete-button:focus,
.modal-delete:hover,
.modal-delete:focus {
  background-color: red;
  color: white;
  border-color: red;
}

#add-button {
  position: absolute;
  top: 10px;
  right: 0px;
  width: 6rem;
}

#login-button:hover {
  background-color: rgb(75, 165, 255);
  color: white;
}

#sign-up-button:hover {
  color: dodgerblue;
  background-color: rgb(233, 244, 255);
}

#custom-dv-button-container {
  /* position: relative; */
  max-width: 315px;
  margin: 0 auto;
}

#custom-dv-button {
  margin: 10px 0;
}

#custom-label-button-container {
  max-width: 275px;
  margin: 0 auto;
}

#custom-label-create-button,
#custom-label-add-button,
#custom-label-save-button {
  margin: 10px 0 5px 0;
}

/* height: 65px with nav-buttons */
/* NAV BUTTONS */
.nav-button-backer {
  position: fixed;
  top: 50px;
  height: 25px;
  width: 100%;
  z-index: 10;
  background-color: white;
}

#nav-button-toggle,
#tab-button-toggle {
  display: flex;
  justify-content: space-evenly;
  position: fixed;
  width: 100%;
  z-index: 11;
}

#nav-button-toggle {
  top: 67px;
}

#tab-button-toggle {
  top: 85px;
}

#button-toggle-left,
#button-toggle-center,
#button-toggle-right {
  width: 90px;
  font-weight: bold;
  text-align: center;
  padding: 2px 0;
}

#tab-toggle-left,
#tab-toggle-right {
  width: 135px;
  font-weight: normal;
  text-align: center;
  padding: 5px 0;
}

#button-toggle-left:hover,
#button-toggle-center:hover,
#button-toggle-right:hover,
.button-toggle-active {
  color: white;
  background-color: var(--primary);
  border: 1px var(--primary) solid;
  cursor: pointer;
}

#button-toggle-left {
  border: 1px var(--primary) solid;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

#tab-toggle-left,
#tab-toggle-right {
  border: 1px var(--primary) solid;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom: none;
}

#tab-toggle-left:hover,
#tab-toggle-right:hover {
  color: dodgerblue;
  cursor: pointer;
}

#tab-toggle-left:focus,
#tab-toggle-right:focus,
.tab-toggle-active {
  color: var(--primary);
  background-color: white;
}

.tab-toggle-inactive {
  color: white;
  background-color: var(--primary);
}

#button-toggle-center {
  border-top: 1px var(--primary) solid;
  border-bottom: 1px var(--primary) solid;
}

#button-toggle-right {
  border: 1px var(--primary) solid;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.button-toggle-inactive {
  color: var(--primary);
  background-color: white;
}

/* LOGIN */
.login-container {
  margin: 20px auto;
  width: 90%;
  max-width: 315px;
  padding: 10px;
}

#login-error-message {
  color: red;
  font-size: 0.9rem;
  width: 90%;
  margin: 40px auto;
  text-align: center;
}

#bottom-li {
  position: absolute;
  bottom: 100px;
  width: 100%;
  border-top: 1px solid lightgrey;
}

.nutritionix-attrib {
  position: fixed;
  bottom: 60px;
  left: 20%;
  width: 60%;
  background-color: white;
  border: 1px #cecece solid;
  border-radius: 3px;
  padding: 5px;
  margin-bottom: 1rem;
  font-weight: bold;
  z-index: -1;
}

.meal-container,
.custom-label-container {
  color: var(--secondary);
  position: relative;
  margin: 0 auto;
  width: 100%;
  max-width: 600px;
}

/* top: 40 with nav-buttons */
.meal-container {
  top: 0px;
}

.custom-label-container {
  top: 60px;
}

/* top: 100 with nav-buttons */
.day-header {
  position: -webkit-sticky;
  position: sticky;
  top: 58px;
  height: 51px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 5px;
  margin-bottom: 10px;
  background-image: linear-gradient(white, #e4e4e4);
  color: dodgerblue;
  z-index: 11;
  /* background-image: linear-gradient(white, #e0e0e0);
  border-bottom: 1px var(--secondary) solid;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px; */
}

.day-header > div {
  font-size: 1.1rem;
  padding: 2px 5px;
  text-align: center;
}

.meal-card {
  width: 100%;
  height: 50px;
  display: flex;
  border-bottom: 1px solid #efefed;
  justify-content: space-between;
  align-items: center;
  margin: 9px 0;
  padding: 0 7px;
  color: var(--secondary);
}

.meal-card:hover {
  background-image: linear-gradient(white, #f8f8f8);
}

.meal-card-cont1 {
  width: 50%;
}
.meal-card-cont3 {
  width: 10%;
}

.virtual-check {
  position: relative;
  left: 92%;
  height: 0;
  width: 0;
}

input[type='checkbox']:not(:checked),
input[type='checkbox']:checked {
  width: 35px;
  height: 70px;
  opacity: 0;
  pointer-events: all;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
}

.meal-card-foodname {
  font-size: 1.2rem;
  overflow: hidden;
  height: 25px;
}

.meal-card-timestamp {
  font-size: 0.9rem;
  overflow: hidden;
  height: 20px;
}
.meal-card-cont2 {
  font-size: 1.1rem;
  width: 20%;
  text-align: right;
  overflow: hidden;
  border: 1px green white;
  margin: 0 3px;
}

.meal-card-stats {
  overflow: hidden;
  height: 23px;
}

.meal-review-container {
  position: relative;
  top: 5px;
  width: 100%;
  margin: 0 auto;
}

.meal-thumb-cont {
  width: 100%;
  height: 70px;
  margin: 5px 0;
  display: flex;
  justify-content: center;
}

.meal-thumb-cont > img {
  height: 70px;
}

.back-arrow {
  position: absolute;
  /* left: 20px; */
  /* top: 80px; */
  font-size: 28px;
  color: white;
  /* color: var(--primary); */
}

.meal-mod-container {
  margin: 0 auto;
  width: 280px;
  padding: 10px 0;
  color: var(--secondary);
}

.edit-container {
  margin: 30px 0;
  border: 1px solid var(--secondary);
}

.textarea-container {
  margin: 0 auto;
  width: 90%;
}

.textarea2-container {
  margin: 0 auto;
  margin-top: 10px;
  width: 100%;
}

.picker {
  width: 9rem;
  line-height: 2rem;
  border: 1px solid blue;
  border-radius: 8px;
  text-align: center;
  background-color: white;
}

label {
  color: #9e9e9e;
}

#date-edit {
  width: 9.5rem;
  text-align: center;
  margin: 5px 20px 0 0;
}

#time-edit {
  width: 6.8rem;
  text-align: center;
  margin: 5px 0;
}

.date-time-container {
  display: flex;
  justify-content: space-around;
  margin: 0 15px;
}

h6 {
  text-align: center;
}

#edit-button {
  width: 90%;
  margin: 10px 5%;
  border: 1px solid var(--secondary);
  color: var(--secondary);
}

#edit-button:hover,
#edit-button:focus {
  color: white;
  background-color: var(--secondary);
}

.custom-button-container {
  display: flex;
  justify-content: space-around;
  position: fixed;
  top: 66px;
  padding: 6px 0;
  width: 90%;
  left: 5%;
}

.merge-backer {
  position: fixed;
  top: 50px;
  height: 65px;
  width: 100%;
  z-index: 12;
  background-color: grey;
}

.merge-container > p,
.merge-container-custom > p {
  min-width: 290px;
  text-align: center;
  color: dodgerblue;
  background-color: white;
  border: 1px solid dodgerblue;
  border-radius: 4px;
  padding: 5px 10px;
  font-style: italic;
}

#merge-button {
  width: 30%;
  font-weight: normal;
  height: 2.5rem;
  padding: 0;
  margin: 5px 0;
}

#batch-delete-button {
  width: 30%;
  background-color: red;
  color: white;
  font-weight: normal;
  border: 1px solid red;
  height: 2.5rem;
  padding: 0;
  margin: 5px 0;
}

#batch-delete-button:hover {
  color: red;
  background-color: white;
}

#create-label-button {
  width: 90%;
}

#hidden-sidenav-close-button,
#hidden-menu-close-button {
  display: none;
}

.empty-log {
  position: absolute;
  top: 125px;
  width: 80%;
  left: 10%;
  max-width: 500px;
  /* margin: 0 auto; */
  text-align: center;
  color: var(--secondary);
  border-top: 1px #efefed solid;
  border-bottom: 1px #efefed solid;
  padding: 6px 5px;
}

.spinner-container {
  position: relative;
  top: 70px;
  text-align: center;
}

#modal {
  background-color: rgba(0, 0, 0, 0.9);
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
}

#modal:empty {
  display: none;
}

.modal-container {
  background-color: white;
  margin: 0 auto;
  width: 270px;
  max-width: 500px;
  padding: 15px;
  border-radius: 5px;
  text-align: center;
}

.modal-button-container {
  display: flex;
  justify-content: center;
  background-color: white;
  padding-top: 20px;
  width: 100%;
  max-width: 500px;
  z-index: 12;
}

#modal button {
  margin: 0 6px;
  font-weight: normal;
}

#modal p {
  color: var(--secondary);
  font-size: 1.1rem;
  line-height: 2rem;
}

.daychart-container {
  display: flex;
  justify-content: center;
  margin: 0 auto;
}

canvas {
  max-width: 500px;
}
